import React from "react"
import { Link } from "gatsby"
import Swiper from "react-id-swiper"
import "swiper/dist/css/swiper.min.css"
import PlaceholderImage from "@images/placeholder-image.jpg"

const ProductSwiper = ({ bgSize, products }) => {
  console.log("products", products)
  const params = {
    speed: 800,
    // loop: true,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: "#product-next",
      prevEl: "#product-prev",
    },
    slidesPerView: 3,
    spaceBetween: 30,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      992: {
        slidesPerView: 3,
      },
    },
  }
  return (
    <div className="product-swiper-wrapper">
      <div className="product-swiper-nav">
        <div
          id="product-next"
          className="swiper-button-next"
          tabIndex="0"
          role="button"
          aria-label="Next slide"
        ></div>
        <div
          id="product-prev"
          className="swiper-button-prev"
          tabIndex="0"
          role="button"
          aria-label="Previous slide"
        ></div>
      </div>
      <div className="row justify-content-center">
        <div className="col-10">
          <Swiper {...params}>
            {products.map((prod, index) => (
              <div className="product-item" key={index}>
                <Link to={prod.link}>
                  <div className="product-image mb-2">
                    <div
                      className="image-bg"
                      style={{
                        backgroundImage: `url(${
                          prod.image !== null ? prod.image : PlaceholderImage
                        })`,
                        backgroundSize: `${
                          bgSize !== undefined ? bgSize : "cover"
                        } `,
                      }}
                    ></div>
                  </div>
                  <span className="d-block product-name">{prod.name}</span>
                  <span className="d-block text-muted font-italic lead product-price">
                    starting at {prod.minPrice}
                  </span>
                </Link>
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default ProductSwiper
