import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import ProductSwiper from "@components/productSwiper"
import Fade from "react-reveal/Fade"

// swiper
import Swiper from "react-id-swiper"
import "swiper/dist/css/swiper.min.css"

// images
import Reclaimed from "@images/pexels-curtis-adams-3773583.jpeg"
import GoldStar from "@images/review-star.svg"
import Commercial from "@images/commercial.jpg"
import Residential from "@images/residential.jpg"
import Craftmanship1 from "@images/pexels-max-vakhtbovych-6265834.jpeg"
import Craftmanship2 from "@images/pexels-curtis-adams-3288103.jpeg"
import Doors from "@images/doors.jpg"
import Countertops from "@images/countertops.jpg"
import Cabinetry from "@images/cabinetry.jpg"
import Beams from "@images/beams.jpg"
import AccentWall from "@images/accent-wall.jpg"
import CustomFurntiure from "@images/custom-furniture.jpg"
import Railings from "@images/railings.jpg"
import Mantels from "@images/mantels.jpg"
import FloatingShelves from "@images/floating-shelves.jpg"
import PlaceholderImage from "@images/placeholder-image.jpg"
import HeroVideoFallback from "@images/pexels-max-vakhtbovych-6284230.jpeg"

// placeholder products - delete later
import product1 from "@images/delete-later/oak-butcher-block-floating-shelves.jpg"
import product2 from "@images/delete-later/knotty-alder-mantel.jpg"
import product3 from "@images/delete-later/red-oak-edge-grain-countertop.jpg"
import product4 from "@images/delete-later/walnut-beams.jpg"
import product5 from "@images/delete-later/red-oak-beams.jpg"
import product6 from "@images/delete-later/maple-end-grain-countertop.jpg"

const DenverIndexPage = ({ data, location }) => {
  // reviews
  const reviews = [
    {
      reviewBody:
        "Had butcher block countertops installed from Eden Oaks. They are beautiful, easy to clean and care for. A timeless look for a 1945 home. Who wants cold and ubiquitous granite when you can have warm reclaimed unique butcher block!? Freddie and staff were easy to work with - highly recommended.",
      name: "Andrea T.",
    },
    {
      reviewBody:
        "Eden Oaks just wrapped up new wrought iron railings for my main staircase.  My wife has been waiting 10 years.  Multiple contractors flaked on us before we engaged with Eden Oaks. She is thrilled with the results.  Very glad we chose Eden Oaks.",
      name: "Collin S.",
    },
    {
      reviewBody:
        "I enlisted the help of Eden Oaks to build a dining room table as a surprise for my wife. I showed Freddie a few picture of table she liked in a catalog and described the space in which the table would be utilized. Freddie and his team more than surpassed my expectations.",
      name: "Thomas M.",
    },
  ]

  // placeholder products -- delete later
  const featuredProducts = [
    {
      name: "Oak Butcher Block Floating Shelves",
      image: product1,
      minPrice: "300",
      link: "/",
    },
    {
      name: "Knotty Alder Mantel",
      image: product2,
      minPrice: "300",
      link: "/",
    },
    {
      name: "Red Oak Edge Grain Countertop",
      image: product3,
      minPrice: "300",
      link: "/",
    },
    {
      name: "Walnut Beams",
      image: product4,
      minPrice: "300",
      link: "/",
    },
    {
      name: "Red Oak Beams",
      image: product5,
      minPrice: "300",
      link: "/",
    },
    {
      name: "Maple End Grain Countertop",
      image: product6,
      minPrice: "300",
      link: "/",
    },
  ]

  return (
    <Layout location={location}>
      <SeoComponent
        title="Architectural Woodwork - Custom Woodworking Company Denver"
        description="Eden Oaks provides architectural woodwork to homes and companies of all sizes. Our custom woodworking company serves Denver and beyond to create stunning custom designs."
      />
      <div className="home-hero">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <Fade>
                <>
                  <span className="heading-span d-block h4 text-white">
                    Premier Custom Woodshop
                  </span>
                  <h1 className="display-3 text-white">
                    Custom Woodworking
                    <br />
                    in Denver
                  </h1>
                </>
              </Fade>
              <Fade>
                <>
                  <Link to="/denver/contact-us" className="btn btn-primary m-2">
                    Contact Us
                  </Link>
                  <Link
                    to="/shop"
                    className="btn btn-primary m-2 btn-white-transparent"
                  >
                    Shop Now
                  </Link>
                </>
              </Fade>
            </div>
          </div>
        </div>
        <div className="background-media">
          <div className="video-bg d-none d-md-block">
            <video muted loop autoPlay poster={HeroVideoFallback}>
              <source
                src="https://res.cloudinary.com/dqiadlztd/video/upload/v1617009407/eden-video-bg-01_t8qvdd.mp4"
                type="video/mp4"
              ></source>
            </video>
          </div>
        </div>
      </div>
      <div id="products-section" className="py-10 bg-white">
        <div className="container">
          <Fade>
            <div className="section-heading text-center">
              <span>Homemade Wood Furniture</span>
              <h2>Featured Collections</h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <p className="my-5 text-center">
                  We build countertops, center islands, tables, bars, stair
                  treads, barn doors, and anything you can dream up!  Our pieces
                  are made to order and are one of a kind. All of our wood is
                  100% American-sourced and salvaged.
                </p>
              </div>
            </div>
          </Fade>
          <Fade>
            <div className="product-category-masonry">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <Link to="/denver/doors">
                    <div className="prod-categ-img">
                      <div
                        className="image-bg"
                        style={{ backgroundImage: `url(${Doors})` }}
                      ></div>
                    </div>
                    <span className="d-block mt-3 mb-0 h5 text-uppercase">
                      Doors
                    </span>
                  </Link>
                </div>
                <div className="col-md-6 mb-4">
                  <Link to="/denver/countertops">
                    <div className="prod-categ-img">
                      <div
                        className="image-bg"
                        style={{ backgroundImage: `url(${Countertops})` }}
                      ></div>
                    </div>
                    <span className="d-block mt-3 mb-0 h5 text-uppercase">
                      Countertops
                    </span>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <Link to="/denver/cabinetry">
                        <div className="prod-categ-img">
                          <div
                            className="image-bg"
                            style={{ backgroundImage: `url(${Cabinetry})` }}
                          ></div>
                        </div>
                        <span className="d-block mt-3 mb-0 h5 text-uppercase">
                          Cabinetry
                        </span>
                      </Link>
                    </div>
                    <div className="col-md-12 mb-4">
                      <Link to="/denver/beams">
                        <div className="prod-categ-img">
                          <div
                            className="image-bg"
                            style={{ backgroundImage: `url(${Beams})` }}
                          ></div>
                        </div>
                        <span className="d-block mt-3 mb-0 h5 text-uppercase">
                          Beams
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <Link to="/denver/accent-wall">
                    <div className="prod-categ-img">
                      <div
                        className="image-bg"
                        style={{ backgroundImage: `url(${AccentWall})` }}
                      ></div>
                    </div>
                    <span className="d-block mt-3 mb-0 h5 text-uppercase">
                      Accent Walls
                    </span>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-4">
                  <Link to="/denver/custom-furniture">
                    <div className="prod-categ-img">
                      <div
                        className="image-bg"
                        style={{ backgroundImage: `url(${CustomFurntiure})` }}
                      ></div>
                    </div>
                    <span className="d-block mt-3 mb-0 h5 text-uppercase">
                      Custom Furniture
                    </span>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Link to="/denver/railings">
                    <div className="prod-categ-img">
                      <div
                        className="image-bg"
                        style={{ backgroundImage: `url(${Railings})` }}
                      ></div>
                    </div>
                    <span className="d-block mt-3 mb-0 h5 text-uppercase">
                      Railings
                    </span>
                  </Link>
                </div>
                <div className="col-md-4 mb-4">
                  <Link to="/denver/mantel">
                    <div className="prod-categ-img">
                      <div
                        className="image-bg"
                        style={{ backgroundImage: `url(${Mantels})` }}
                      ></div>
                    </div>
                    <span className="d-block mt-3 mb-0 h5 text-uppercase">
                      Mantels
                    </span>
                  </Link>
                </div>
                <div className="col-md-4 mb-4">
                  <Link to="/denver/floatingshelves">
                    <div className="prod-categ-img">
                      <div
                        className="image-bg"
                        style={{ backgroundImage: `url(${FloatingShelves})` }}
                      ></div>
                    </div>
                    <span className="d-block mt-3 mb-0 h5 text-uppercase">
                      Floating Shelves
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="about-section text-center text-md-left bg-white">
        <div className="container">
          <Fade>
            <div className="row justify-content-center">
              <div className="col-md-3 mb-4 mb-md-0">
                <div className="section-heading light text-center text-md-left">
                  <h2 className="h4">About Us</h2>
                </div>
              </div>
              <div className="col-md-9 col-lg-8">
                <p>
                  Eden Oaks is your trusted source for reclaimed oak butcher
                  block. Other select domestic hardwoods also available. We
                  supply this unique, eco friendly material to builders,
                  designers, artists, and wood-lovers everywhere.{" "}
                  <Link to="/about">READ MORE</Link>
                </p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="featured-products-section py-10 bg-white">
        <div className="container">
          <Fade>
            <div className="section-heading text-center">
              <span>Best sellers</span>
              <h2>Featured Products</h2>
            </div>
          </Fade>
          <div className="mt-5">
            <LazyLoadComponent>
              <ProductSwiper products={featuredProducts} />
            </LazyLoadComponent>
          </div>
        </div>
      </div>
      <div className="bg-light py-10">
        <div className="container">
          <Fade>
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="section-heading text-center">
                  <h2 className="font-weight-light font-italic h4">
                    To include craftsmanship in a home or office is to elevate
                    your environment with artistic flair and stylish
                    functionality.
                  </h2>
                </div>
              </div>
            </div>
          </Fade>
          <div className="row no-gutters align-items-center mt-5">
            <div className="col-md-6 order-2 order-md-1">
              <div className="row no-gutters">
                <div className="col-12">
                  <Fade>
                    <div
                      className="bg-dark img-wrapper-square"
                      style={{ backgroundImage: `url(${Craftmanship1})` }}
                    ></div>
                  </Fade>
                </div>
                <div className="col-12">
                  <Fade delay={500}>
                    <div className="p-3 p-md-5">
                      <p>
                        Eden Oaks invites you to take your home or office to the
                        next level with custom woodworking in Colorado Springs.
                        Not only do we offer 
                        <Link to="/denver/custom-furniture">
                          handmade wood furniture
                        </Link>{" "}
                        like tables, bars, bed frames and credenzas, but we also
                        provide a variety of services that incorporate wood into
                        the walls, stairs, and doorways of your home for a
                        seamless integration of natural design.
                      </p>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="row no-gutters">
                <div className="col-12 order-2 order-md-1">
                  <Fade delay={500}>
                    <div className="p-3 p-md-5">
                      <p>
                        Each of us has had the experience of ordering a piece of
                        furniture for delivery, splaying all of the slabs and
                        fasteners across the floor for optimal assessment,
                        working step-by-step through the complicated
                        instructions that always include extra parts that don’t
                        seem to fit anywhere, ultimately, just to finish the
                        process with a common piece of furniture that all your
                        neighbors also have (if you ever actually manage to
                        build it right!).
                      </p>
                    </div>
                  </Fade>
                </div>
                <div className="col-12 order-1 order-md-2">
                  <Fade>
                    <div
                      className="bg-dark img-wrapper-square"
                      style={{ backgroundImage: `url(${Craftmanship2})` }}
                    ></div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-furniture-section py-10">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <Fade>
                <div className="bg-dark-50 text-white text-center p-3 p-md-5">
                  <div className="section-heading text-center">
                    <h2 className="text-white">Custom Furniture</h2>
                  </div>

                  <p className="my-5">
                    All of our custom furniture pieces are built by hand in our
                    Colorado Springs and Arvada workshops. We pair our reclaimed
                    oak butcher block with a variety of other materials
                    including steel, iron and maple. Design your own piece,
                    choose a design you like from our website, or let us create
                    something entirely unique for you.
                  </p>
                  <Link
                    to="/denver/custom-furniture"
                    className="btn btn-primary"
                  >
                    Read More
                  </Link>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div className="background-media">
          <div className="video-bg d-none d-md-block">
            <video muted loop autoPlay>
              <source
                src="https://res.cloudinary.com/dqiadlztd/video/upload/v1617009384/eden-video-bg-02_oz7krw.mp4"
                type="video/mp4"
              ></source>
            </video>
          </div>
        </div>
      </div>
      <div className="py-10 bg-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <Fade>
                <>
                  <div className="section-heading text-center">
                    <h2 className="font-weight-light font-italic h4">
                      If your goal is to create a welcoming atmosphere with a
                      personal touch, mass-produced furniture won’t cut it.
                    </h2>
                  </div>
                  <p className="mt-5 text-center">
                    Whether you are looking for a vintage double-door entryway
                    composed of reclaimed, raw-edge lumber or a sleek and modern
                    mahogany dining table that fits the whole family, the
                    professional craftsmen at Eden Oaks in Colorado Springs can
                    make your vision come to life.
                  </p>
                </>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container-fluid px-0">
          <div className="row no-gutters">
            <div className="col-lg-6">
              <div
                className="half-fill-section"
                style={{ backgroundImage: `url(${Residential})` }}
              >
                <div className="bg-dark-50 text-white text-center h-100 text-wrapper">
                  <div className="section-heading text-center">
                    <h2 className="text-white">Residential</h2>
                  </div>
                  <p className="my-4">
                    At Eden Oaks, we can design and create wooden furnishings
                    that will serve as great accents or function the way you
                    want them to.
                  </p>
                  <Link to="/denver/residential" className="btn btn-primary">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="half-fill-section"
                style={{ backgroundImage: `url(${Commercial})` }}
              >
                <div className="bg-dark-50 text-white text-center h-100 text-wrapper">
                  <div className="section-heading text-center">
                    <h2 className="text-white">Commercial</h2>
                  </div>
                  <p className="my-4">
                    We take pride in our ability to create custom woodwork for
                    commercial use. Spruce up your office space, shop,
                    restaurant or other place of business with a unique touch to
                    correspond exactly with your design.
                  </p>
                  <Link to="/denver/commercial" className="btn btn-primary">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonials-section py-10 bg-white">
        <div className="container">
          <Fade>
            <div className="section-heading text-center">
              <span>Reviews</span>
              <h2>What Our Clients Say</h2>
            </div>
          </Fade>
        </div>

        <div className="container-fluid px-xl-0">
          <div className="mt-5">
            <LazyLoadComponent>
              <div className="review-swiper-wrapper">
                <Swiper
                  speed={1000}
                  centeredSlides={true}
                  spaceBetween={50}
                  slidesPerView={2}
                  loop={true}
                  pagination={{
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true,
                  }}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  breakpoints={{
                    992: {
                      autoHeight: false,
                      slidesPerView: 1,
                    },
                    320: {
                      autoHeight: true,
                      slidesPerView: 1,
                      spaceBetween: 30,
                    },
                  }}
                >
                  {reviews.map((review) => (
                    <div className="bg-light testimonial-slide">
                      <p>“{review.reviewBody}”</p>
                      <div className="testimonial-stars mt-4 mb-2">
                        <img src={GoldStar} alt="star" />
                        <img src={GoldStar} alt="star" />
                        <img src={GoldStar} alt="star" />
                        <img src={GoldStar} alt="star" />
                        <img src={GoldStar} alt="star" />
                      </div>
                      <span className="font-weight-bold">{review.name}</span>
                    </div>
                  ))}
                </Swiper>
              </div>
            </LazyLoadComponent>
          </div>
        </div>
      </div>
      <div className="bg-light py-10">
        <div className="container">
          <div className="row justify-content-between align-items-end">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <Fade>
                <div className="section-heading">
                  <h2 className="font-weight-light font-italic h4">
                    We are inspired by the warmth and liveliness of reclaimed
                    and repurposed wood.
                  </h2>
                </div>
              </Fade>
              <Fade>
                <p className="my-5">
                  Our goal is to incorporate that energy into your home or
                  office through custom woodworking. Colorado Springs, nestled
                  at the base of the Rocky Mountains, is home to natural
                  wonders, abundant sunshine, and crisp, clean air that stems
                  from the mountain forest and the rich culture of the
                  neighboring metropolitan areas. Our question to you, then, is
                  why separate your home or restaurant or workspace from the
                  place that you call home? Embrace the beauty of Colorado
                  Springs with custom woodworking from Eden Oaks.
                </p>
              </Fade>
              <Fade>
                <div className="bg-white p-3 p-md-4">
                  <p className="small">
                    If you are outside of the Colorado Springs area, you can
                    find 
                    <Link to="/denver">
                      custom woodworking from our Denver craftsmen
                    </Link>
                    , as well. Call now to discuss your handmade wood furniture,
                    accent wall, or cabinetry project and bring the beauty of
                    Colorado inside!
                  </p>
                  <Link
                    className="btn btn-primary btn-square-arrow"
                    to="/denver"
                  >
                    Eden Oaks Denver
                  </Link>
                </div>
              </Fade>
            </div>

            <div className="col-lg-5">
              <Fade delay={500}>
                <div
                  className="img-wrapper-tall"
                  style={{
                    backgroundImage: `url(${Reclaimed})`,
                  }}
                ></div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-section bg-white">
        <div className="container">
          <Fade>
            <div className="section-heading text-center">
              <span>Latest Posts</span>
              <h2>From the Blog</h2>
            </div>
          </Fade>
          <div className="row justify-content-center mt-5">
            {data.allSanityPost.edges.map((blog) => (
              <div className="col-md-4 mb-5">
                <div className="blog-item-wrapper h-100">
                  <Link
                    to={`/blog/` + blog.node.slug.current}
                    aria-label={blog.node.title}
                  >
                    <div className="blog-img">
                      <div
                        className="image-bg"
                        style={{
                          backgroundImage: `url(${
                            blog.node.mainImage &&
                            blog.node.mainImage.asset &&
                            blog.node.mainImage.asset.url
                              ? blog.node.mainImage.asset.url
                              : PlaceholderImage
                          })`,
                        }}
                      ></div>
                    </div>
                  </Link>
                  <span className="d-block text-uppercase my-3 small text-muted">
                    {blog.node.publishedAt}
                  </span>
                  <Link
                    className="blog-title"
                    to={`/blog/` + blog.node.slug.current}
                  >
                    <h3 className="h5 mb-3">{blog.node.title}</h3>
                  </Link>
                  <p className="small">{blog.node.excerpt}</p>
                  <Link
                    className="read-more-link"
                    to={`/blog/` + blog.node.slug.current}
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DenverIndexPage

export const denverIndexPageQuery = graphql`
  query denverIndexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              url
            }
          }
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`
